.site-footer {
    @extend .bg-primary,
    .py-md-5,
    .py-3,
    .text-white;

    a {
        @extend .text-reset;
    }

    &-top {
        @extend .d-none,
        .d-md-block;

        .sf-nav {
            @extend .d-flex;

            &-col {
                @extend .mr-4;
                width: calc(33.3% - 1.5rem);

                &:not(:last-child) {
                    @extend .border-right;
                }
            }

            ul {
                @extend .list-unstyled,
                .m-0,
                .p-0;

                li {
                    @extend .py-1;


                }
            }
        }
    }

    &-bottom {
        @extend .border-top,
        .border-bottom,
        .mt-0,
        .mt-md-5,
        .d-md-flex,
        .align-items-center,
        .text-center,
        .text-md-left;

        @include media-breakpoint-down(sm) {
            border: 0 !important;
        }

        p {
            font-size: 14px;
        }

        p,
        .sf-sm-icons,
        .sf-feedback {
            @extend .my-2;
        }

        .sf-feedback {
            @extend .order-md-2,
            .pl-md-4;
        }

        .sf-sm-icons {
            @extend .order-md-1,
            .px-4,
            .border-right,
            .border-left,
            .d-md-flex,
            .justify-content-between;

            a {
                &+a {
                    @extend .ml-2;
                }
            }

            @include media-breakpoint-down(sm) {
                border: 0 !important;
                padding: .5rem 0;
            }
        }

        .sf-copyrights {
            @extend .order-md-0;
        }

        @include media-breakpoint-up(lg) {
            .sf-feedback {
                width: 64%;
            }

            .sf-sm-icons {
                width: 19%;
            }

            .sf-copyrights {
                width: 17%;
            }
        }

        @include media-breakpoint-only(md) {
            .sf-feedback {
                width: 59%;
            }

            .sf-sm-icons {
                width: 22%;
            }

            .sf-copyrights {
                width: 19%;
            }
        }
    }
}

html[dir="rtl"] {
    .sf-nav-col {
        @extend .mr-0,
        .ml-4;

        &:not(:last-child) {
            @extend .border-left,
            .border-right-0;
        }
    }

    .sf-feedback,
    .sf-copyrights {
        @extend .text-md-right;
    }

    .sf-feedback {
        @extend .pl-0,
        .pr-4;
    }
}