.mlc-serv-sec {
    @extend .py-3;

    .mlc-serv {
        &-boxes {
            /* @extend .my-5; */
            @extend .my-3;
        }

        &-box {
            @extend .d-lg-flex,
            .p-4,
            .border-bottom,
            .border-primary,
            .my-2,
            .bg-light;
            /* background-color: #fafafa; */
            /* box-shadow: 0 1px 2px 0; */
            border-width: 3px !important;

            .btn,
            .form-control {
                @extend .rounded-0;
            }

            .btn {
                @extend .font-weight-semibold;
            }

            &-icon {
                @extend .text-primary;
                /* font-size: 2rem; */
                font-size: 1.25rem;
            }

            &-content {
                h4 {
                    @extend .text-primary,
                    .mt-1,
                    .mb-3
                    /* ,
                    .font-weight-semibold */
                    ;
                    font-size: 1.25rem;
                }

                .form-control {
                    height: calc(1.5em + 1rem - 4px);
                }

                .btn {
                    height: calc(1.5em + 1rem - 4px);
                    padding: .3rem 1rem;
                }
            }

            @include media-breakpoint-up(lg) {
                &-icon {
                    margin-right: 1rem;
                    width: 1.5rem;
                }

                &-content {
                    width: calc(100% - 2.5rem);
                }
            }
        }
    }
}

.latest-news {
    &-sec {
        background-color: #f7f7f7;
        padding: 2rem 0;
    }

    //@extend .py-4;
    &-card {
        @extend .border-bottom,
        .border-primary,
        .my-2
        /* ,
        .pb-4 */
        ;
        border-width: 3px !important;
        background: #FFF;
        /* box-shadow: 0 1px 2px 0; */
        padding: 1rem;

        img {
            transition: all .5s;
        }

        /* &:hover {
            .embed-responsive {
                img {
                    transform: scale(1.1);
                }
            }
        } */

        h4 {
            @extend .my-3,
            .font-weight-semibold;
            font-size: 1.25rem;
        }

        small {
            @extend .text-muted;
        }
    }
}

.browse-loc-sec {
    @extend .my-5;

    .loc-bg {
        @extend .p-4;
        background: url("../images/loc-bg.jpg") no-repeat center right;
        background-size: cover;
    }

    .bl-highlights {
        @extend .d-sm-flex,
        .justify-content-between,
        .mt-3;
        color: $theme-color;

        h3,
        h6 {
            @extend .m-0,
            .font-weight-semibold;
        }

        h3 {
            /* @extend .font-weight-light; */
            font-size: 2rem;
        }

        h6 {
            /* @extend .font-weight-semibold; */
            font-size: 14px;
        }
    }

    .bl-online-chat {
        @extend .h-100,
        .text-white,
        .p-4;
        background-color: #393939;
    }
}

html[dir="rtl"] {
    .mlc-serv-box-icon {
        @extend .mr-0,
        .ml-lg-3;
    }
}