body {
    font-family: 'daxregular', $system-font-stack;
    /* color: #7e7e7e; */
    color: #202020;

    &:not(.home-page) {
        background-color: #fafafa;
    }

    @include media-breakpoint-up(lg) {
        //padding-top: calc(10rem + 9px);
        padding-top: calc(8rem + 13px);
    }

    @include media-breakpoint-down(md) {
        //padding-top: calc(7rem + 1px);
        padding-top: calc(5rem + 1px);
    }
}

.font-weight-semibold {
    font-family: 'daxmedium', $system-font-stack;
}

.font-weight-bold {
    font-family: 'daxbold', $system-font-stack;
}

.font-weight-extrabold {
    font-family: 'daxextrabold', $system-font-stack;
}

.font-weight-light {
    font-family: 'daxlight', $system-font-stack;
}

.font-weight-black {
    font-family: 'daxblack', $system-font-stack;
}

img {
    max-width: 100%;

    &.embed-responsive-item {
        object-fit: cover;
    }
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: normal;
}

p {
    /* margin: 1.5rem 0;
    font-size: 1.125rem; */
}

.mw-1170 {
    max-width: 1170px;
}

.btn-outline-white {
    @extend .border,
    .border-white,
    .text-white;

    &:hover {
        @extend .bg-white,
        .text-dark;
    }
}

.sec-title {
    @extend .text-primary,
    .font-weight-semibold,
    .mb-4;
    font-size: 2.5rem;
}

.back-to-top {
    @extend .position-fixed;
    right: 1.5rem;
    bottom: 1.5rem;
    display: none;
    z-index: 111;

    a {
        @extend .btn,
        .btn-primary,
        .p-0,
        .d-flex,
        .justify-content-center,
        .align-items-center;
        width: 3rem;
        height: 3rem;

        i {
            @extend .fa-2x;
        }
    }
}

.breadcrumb-wrap {
    @extend .border-top,
    .bg-white;

    .container-fluid {
        @extend .py-3;
        /* padding-left: 2rem;
        padding-right: 2rem; */
    }

    &.transparent {
        @extend .bg-transparent,
        .position-relative;
        z-index: 5;

        * {
            color: #FFF;
        }

        &~.site-banner {
            margin-top: -4rem;
            padding-top: 5.5rem !important;
        }
    }
}

.site-content-area {
    padding: 4rem 0;
}

.am-content-section,
.am-sidebar {
    @extend .my-3;
}

.am-sidebar {
    @extend .bg-white,
    .p-4;
    min-height: calc(100% - 6rem - 1.5rem - 2.6875rem);
}

.sidebar-widget {
    h4 {
        @extend .border-bottom,
        .border-primary,
        .mb-3;
    }

    p {
        @extend .my-3;
    }

    ul {
        @extend .list-unstyled,
        .m-0,
        .p-0;

        li {
            a {
                @extend .text-secondary,
                .d-block;
                padding: 2px 0;

                &:hover {
                    @extend .text-decoration-none,
                    .text-primary;
                }
            }
        }
    }

    &+& {
        @extend .mt-4;
    }
}


html[dir="rtl"] {
    .back-to-top {
        right: auto;
        left: 1.5rem;
    }
}