.site-header {
    @extend .position-fixed,
    .bg-white;
    top: 0px;
    left: 0px;
    right: 0px;
    z-index: 50;

    /* .container-fluid {
        padding-left: 0;
        padding-right: 0;
        position: relative;
    } */

    &-primary {
        @extend .position-relative;
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        transition: all .5s;

        &:after {
            @extend .position-absolute,
            .d-block,
            .h-100,
            .w-100;
            content: '';
            background-color: $theme-color;
            left: 50%;
            top: 0;
            transform: translateX(-50%);
            max-width: 1140px;
            z-index: 1;
        }

        .container-fluid {
            @extend .d-flex,
            .justify-content-between,
            .align-items-center,
            .position-relative;
            max-width: 1140px;
        }

        .mob-nav-icon {
            @extend .d-lg-none,
            .position-relative
            /* ,
            .fa-2x */
            ;
            font-size: 1.5rem;
            z-index: 2;

            a {
                @extend .text-white;
            }
        }

        .site-logo {
            @extend .ml-auto,
            .position-relative;
            //max-width: 200px;
            max-width: 125px;
            transition: all .5s;
            z-index: 5;
        }
    }

    &-secondary {
        @extend .position-relative;
        z-index: 3;

        @include media-breakpoint-down(md) {
            .mob-nav-bg {
                background-color: rgba(0, 0, 0, .5);
                width: 100%;
                height: 100%;
                position: fixed;
                left: 0;
                top: 0;
                z-index: 99;
                display: none;
            }
        }

        .site-navs-wrap {
            @extend .d-lg-flex,
            .align-items-center;
            position: relative;

            .site-search-widget {
                @extend .d-none,
                .d-lg-block,
                .ml-auto,
                .position-relative;
                width: 38px;
                transition: all .5s;

                .form-control {
                    border-radius: 2rem;
                    display: none;
                    padding-left: 2.5rem;
                }

                .search-icon {
                    @extend .position-absolute;
                    padding: 1rem;
                    left: 0;
                    top: 50%;
                    transform: translateY(-49%);
                }

                &.focused {
                    width: 250px;

                    .form-control {
                        display: block;
                    }
                }
            }

            .mob-nav-closer {
                @extend .btn,
                .btn-block,
                .btn-light,
                .mb-4,
                .d-block,
                .d-lg-none;
            }

            @include media-breakpoint-down(md) {
                position: fixed;
                left: -320px;
                top: 0;
                bottom: 0;
                background-color: #FFF;
                width: 300px;
                padding: 1.5rem;
                max-height: 100vh;
                overflow-x: hidden;
                transition: all .5s;
                z-index: 100;

                &.toggleMobNav {
                    left: 0;
                }
            }

            ul {
                @extend .list-unstyled,
                .m-0,
                .p-0;

                li {
                    //@extend .font-weight-semibold;

                    a {
                        @extend .d-block;
                        transition: all .5s;
                        transition-property: background, color;

                        &:hover {
                            @extend .text-decoration-none;
                        }
                    }

                    &.ksa-vision-logo {
                        @extend .d-flex,
                        .align-items-center,
                        .pl-lg-4,
                        .pt-4,
                        .pt-lg-0;

                        img {
                            max-height: 2.5rem;
                        }
                    }
                }
            }
        }

        .site-nav {

            &-primary,
            &-secondary {
                &>ul {
                    @extend .d-lg-flex;

                    &>li {
                        a {}

                        @include media-breakpoint-down(md) {
                            &.has-submenu {
                                &>a {
                                    position: relative;

                                    &:after {
                                        position: absolute;
                                        content: '';
                                        right: 0;
                                        top: 50%;
                                        border-style: solid;
                                        border-width: 2px 2px 0 0;
                                        display: block;
                                        transition: all .5s;
                                        transform: translateY(-50%) rotate(45deg);
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    li {
                        @extend .position-relative;

                        @include media-breakpoint-down(md) {
                            ul {
                                display: none;
                                padding-left: 1rem !important;
                            }
                        }
                    }
                }
            }

            &-secondary {
                &>ul {
                    @extend .d-lg-flex;

                    &>li {
                        &>a {
                            @extend .d-lg-flex,
                            .align-items-center;

                            i {
                                @extend .mr-lg-2;
                            }

                            @include media-breakpoint-up(lg) {
                                height: 60px;

                            }
                        }
                    }
                }
            }

            &-primary {
                &>ul {
                    &>li {
                        &>a {
                            @extend .d-flex,
                            .align-items-center;
                            font-size: 1.25rem;

                            @include media-breakpoint-up(lg) {
                                padding: 1rem 1.5rem;
                                //height: calc(7rem + 3px);
                                height: calc(80px + 3px);
                            }

                            @include media-breakpoint-down(md) {
                                padding: .5rem 0;
                            }
                        }

                        @include media-breakpoint-down(md) {
                            &.has-submenu {
                                &>a {
                                    &:after {
                                        width: .8rem;
                                        height: .8rem;
                                        border-color: $theme-color;
                                    }
                                }

                                &.opened {
                                    &>a {
                                        &:after {
                                            transform: translateY(-65%) rotate(135deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                ul {
                    li {
                        ul {
                            @extend .clearfix;
                        }

                        @include media-breakpoint-up(lg) {
                            position: static !important;

                            a {
                                color: #FFF;
                                transition: all .5s;
                            }

                            &.active,
                            &:hover>a {
                                //@extend .text-decoration-none;
                                /* background-color: darken($theme-color, 5); */
                                background-color: rgba(255, 255, 255, .4);
                            }

                            ul {
                                /* display: flex !important;
                                flex-wrap: wrap; */
                                padding: 1rem !important;
                                position: absolute;
                                /* background-color: darken($theme-color, 5); */
                                background-color: #FFF;
                                width: 100%;
                                top: 100%;
                                left: 0;
                                z-index: 11;
                                /* transition: all .01s ease-in-out; */
                                margin-top: .5rem !important;
                                /* opacity: 0;
                                visibility: hidden; */
                                display: none;
                                box-shadow: 0 5px 10px rgba(0, 0, 0, .05);

                                li {
                                    width: 25%;
                                    float: left;

                                    a {
                                        color: $theme-color;
                                        padding: .5rem 1rem;
                                    }

                                    &.active a,
                                    a:hover {
                                        /* background-color: lighten($theme-color, 5); */
                                        background-color: #f5f5f5;
                                    }
                                }
                            }

                            &:hover {
                                ul {
                                    margin-top: 0 !important;
                                    /* opacity: 1;
                                    visibility: visible; */
                                    display: block;
                                }
                            }
                        }



                        @include media-breakpoint-down(md) {
                            li {
                                margin: .5rem 0;
                            }
                        }
                    }
                }

                @include media-breakpoint-up(lg) {
                    width: 100%;
                    position: absolute;
                    bottom: 100%;
                    left: 0;
                }

                @include media-breakpoint-down(md) {
                    border-bottom: 1px solid #EEE;
                    margin-bottom: 1.5rem;
                    padding-bottom: 1.5rem;
                }
            }

            &-secondary {
                ul {
                    li {
                        a {
                            color: #7e7e7e;

                            i.fa {
                                transform: scaleX(-1);
                            }

                            @include media-breakpoint-up(lg) {
                                padding: 1rem;
                            }

                            @include media-breakpoint-down(md) {
                                padding: .5rem 0;
                            }
                        }

                        @include media-breakpoint-up(lg) {

                            &.active>a,
                            &:hover>a {
                                background-color: #f0f0f0;
                                color: $theme-color;
                            }
                        }

                        @include media-breakpoint-up(lg) {
                            ul {
                                padding: .5rem !important;
                                position: absolute;
                                background-color: #FFF;
                                width: 200px;
                                top: 100%;
                                left: 0;
                                z-index: 11;
                                transition: all .1s ease-in-out;
                                margin-top: .5rem !important;
                                opacity: 0;
                                visibility: hidden;
                                box-shadow: rgba(0, 0, 0, 0.05) 0px 5px 10px;
                                display: block !important;

                                li {
                                    width: 100%;

                                    a {
                                        padding: .5rem 1rem;
                                    }
                                }
                            }

                            &:hover {
                                ul {
                                    opacity: 1;
                                    visibility: visible;
                                    margin-top: 0 !important;
                                }
                            }
                        }

                        @include media-breakpoint-down(md) {
                            &.has-submenu {
                                &>a {
                                    &:after {
                                        width: .6rem;
                                        height: .6rem;
                                        border-color: #7e7e7e;
                                    }
                                }

                                &.opened {
                                    &>a {
                                        &:after {
                                            transform: translateY(-65%) rotate(135deg);
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &.small-header {
        box-shadow: 0 0 10px rgba(0, 0, 0, .05);

        .site-logo {
            //max-width: 150px;
        }

        .site-header-primary {
            //@extend .py-3;
        }

        .site-header-secondary {
            .site-nav-primary {
                &>ul {
                    &>li {
                        &>a {
                            @include media-breakpoint-up(lg) {
                                //height: calc(5rem + 3px);
                            }
                        }
                    }
                }
            }
        }
    }
}

.site-banner {
    @extend .position-relative,
    .overflow-hidden,
    .d-flex,
    .align-items-center,
    .py-4;

    /* @include media-breakpoint-up(lg) {
        height: 35vw;
        max-height: 500px;
    } */
    min-height: 400px;

    &:after {
        @extend .d-block,
        .position-absolute,
        .w-100,
        .h-100;
        background-color: #000;
        left: 0;
        top: 0;
        opacity: .6;
        z-index: 2;
        content: '';
    }

    &-img {
        @extend .position-absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        min-width: 100%;
        min-height: 100%;
        z-index: 1;
        object-fit: cover;
    }

    .container-fluid {
        @extend .position-relative;
        z-index: 3;
    }

    &-content {
        @extend .text-white;
        max-width: 650px;

        h2 {
            @extend .font-weight-semibold,
            .mb-3;
            font-size: 3rem;
        }

        p {
            @extend .mb-5;
            font-size: 1.3rem;
        }

        .btn {
            @extend .rounded-0,
            .font-weight-semibold;
            min-width: 170px;
            border-width: 2px !important;
        }

        @include media-breakpoint-down(xs) {
            text-align: center;
        }
    }
}

html[dir="rtl"] {
    .site-header-primary {
        .mob-nav-icon {
            order: 1;
        }
    }

    .site-header-secondary {
        .site-navs-wrap {
            .site-search-widget {
                @extend .mr-auto;
                margin-left: 0 !important;
            }
        }
    }

    .site-header-secondary {

        .site-nav-primary {
            //width: auto;

            @include media-breakpoint-up(lg) {
                &>ul {
                    float: left;

                    &>li {
                        position: static !important;
                    }
                }
            }
        }

        .site-nav-primary,
        .site-nav-secondary {
            &>ul {
                &>li {
                    &>a {
                        i {
                            @extend .mr-lg-0,
                            .ml-lg-2;
                        }
                    }

                    &.has-submenu {
                        &>a:after {
                            right: auto;
                            left: 0;
                            transform: translateY(-50%) rotate(225deg);
                        }

                        &.opened {
                            &>a:after {
                                transform: translateY(-50%) rotate(135deg);
                            }
                        }
                    }
                }
            }

            ul {
                li {
                    @extend .position-relative;

                    @include media-breakpoint-down(md) {
                        ul {
                            padding-left: 0 !important;
                            padding-right: 1rem !important;
                        }
                    }
                }
            }
        }
    }
}