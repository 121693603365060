.newsletter-col {
    @extend .bg-white,
    .border-bottom,
    .border-primary;
    border-width: 3px !important;

    h5 {
        @extend .m-0,
        .p-3;
    }

    &-thumb {
        @extend .embed-responsive,
        .overflow-hidden;

        &:before {
            padding-top: 120%;
        }

        img {
            @extend .embed-responsive-item;
            transition: all .5s;
        }
    }

    /* &:hover {
        .newsletter-col-thumb {
            img {
                transform: scale(1.1);
            }
        }
    } */
}